@import url("./css/satoshi.css");

body {
  margin: 0;
  font-family: "Satoshi-Variable", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Satoshi-Variable", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.grid-container {
  display: grid;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  grid-template-columns: repeat(12, 1fr);
  max-width: 997px;
  margin: 120px auto 0 auto;
  /* border: 1px solid blue; */
}


.grid-container-mobile {
  display: grid;
  flex-direction: column;
  max-width: 292px;
  margin: auto;
}