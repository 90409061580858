/* ================================ Header CSS ================================ */
html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: black !important;
}

.fixed-top {
  z-index: 1;
}

.navbar {
  height: 56px;
}

ul,
.text-decoration-none {
  font-weight: 500;
}

.login-btn {
  background-color: #383fec;
  color: #ffffff;
  border-radius: 4px;
}

.login-btn:hover {
  background-color: #040abc;
  color: #ffffff;
}

.register-btn {
  border-color: #383fec;
  color: #383fec;
}

.register-btn:hover {
  background-color: #383fec;
  color: #ffffff;
}

/* ================================ Banner CSS ================================ */
.exclusive {
  background: linear-gradient(180deg, #FF3908 0%, #FF7834 100%);;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 900;
}

h1 {
  font-weight: 900;
  font-size: 4rem;
  line-height: 100%;
}

.custom-width {
  width: max-content !important;
}

.features-h1 {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  grid-column: span 12;
}

.why-op {
  display: grid;
  grid-column: span 12;
  gap: 0;
  justify-content: space-between;
}

.why-op-box {
  border-radius: 4px;
  background: #F2F3FF;
  /* width: 240px; */
  height: 121px;
  grid-column: span 3;
}


.box-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.box-description {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.pricing-section {
  grid-column: span 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-title {
  color: #000;
  font-size: 24px;
  font-weight: 900;
}

.price-desc,
.per-month,
.no-credit {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.price-desc {
  margin-bottom: 68px;
}

.strikethrough-text {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  text-decoration: line-through #FF3908;
  line-height: 100%;
}

.bold-price {
  color: #000;
  font-size: 48px;
  font-weight: 900;
  line-height: 100%;
}

.thirty-days {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}


.get-started-btn {
  height: 34px;
  width: 142px;
  border-radius: 4px;
  background: linear-gradient(180deg, #FF420E 0%, #FF702E 100%);
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  border: none;
  margin-top: 30.5px;
}

.get-all-this {
  grid-column: span 8;
  justify-self: end;
}

.list-box {
  gap: 100px;
}

.get-all-this li {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
}

.custom-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background: url('../images/tick.svg') no-repeat center center;
  margin-right: 16px;

}

.faq-right {
  grid-column: span 3;
}

.faq-left {
  grid-column: span 9;
  justify-self: end;
}

.faq-question {
  border-radius: 4px;
  background: #F0F0F0;
  padding: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  transition: height 0.3s;
  width: 660px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.question-number {
  font-weight: 900;
}

.faq-question.expanded {
  height: auto;
}

.arrow {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.inverted {
  transform: rotate(180deg);
}

.faq-answer {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.inner-footer {
  grid-column: span 12;
}

.footer-logo {
  width: 100px;
  height: 40px;
}

.footer-text {
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.footer-mail {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}

.footer Link {
  color: #555;
}

.footer {
  margin-top: 80px;
}

.grid-container-footer {
  display: grid;
  max-width: 997px;
  margin: 0rem auto 0 auto;
}

.auth-popup {
  height: 388px;
  width: 360px;
  background-color: white;
  padding: 21px 34px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  z-index: 9999999;
}

.auth-popup-api {
  height: 439px;
  padding: 17px 34px;
}

.auth-popup-title {
  color: #000;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -0.8px;
}

.auth-popup-subtitle {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.broker-logo-container {
  margin-top: 27px;
}

.broker-logo {
  width: 90px;
  padding: 8px 6px;
  align-content: center;
  gap: 4px;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
}

.broker-name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.mobile-number-input,
.api-input {
  padding: 8px 12px;
  border-bottom: 1px solid #000;
  background: #FFF;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  color: #555;
  font-size: 14px;
  font-weight: 500;
}

.mobile-number-input {
  margin-top: 46px;

}

.api-input {
  margin-top: 8px;
}



.auth-form-button {
  border-radius: 4px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  background: #383FEC;
  width: 198px;
  border: none;
}

.ac-agarwal-logo {
  width: 39px;
  height: 36px;
}

.boker-name-api-form {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.error-message {
  color: #ff5f22;
  font-size: 14px;
  font-weight: 500;
}

.success-message {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.connect-your-acount {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.form-api-type {
  color: #000;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.64px;
  margin-top: 16px;
}


.connect-dash-btn {
  width: 231px;
  margin-top: -8px;
}

.generate-api-link {
  color: #383FEC;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  margin-top: 27px;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}






@media only screen and (max-width: 786px) {
  h1 {
    font-size: 2.5rem !important;
  }

  .custom-list li  {
    font-size: 14px;
  }

  .price-desc,
  .per-month,
  .no-credit {
    font-size: 14px;
  }

  .features-h1 {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 1404px) {
  h1 {
    font-size: 36px !important;
    line-height: 100%;
  }

  .board-h1 {
    font-size: 2.5rem !important;
    line-height: 48px !important;
  }

  .features-h1 {
    font-size: 2.5rem !important;
  }

  .hero_image {
    height: auto !important;
  }

  .card-text,
  .board-text {
    font-size: 18px !important;
  }
}

/* @media (min-width: 1280px) {
  .container-fluid {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
} */
.banner-container {
  display: grid;
  grid-column: span 12;
}

.banner-left {
  grid-column: span 6;
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.btn-text {
  font-weight: 500;
}

.banner-right {
  grid-column: span 6;
  display: flex;
  justify-content: end;
}

/* ================================ Feature CSS ================================ */
.feature-title {
  font-weight: 500;
  font-size: 24px;
  width: max-content;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}

.card-text {
  font-weight: 500;
  font-size: 20px;
}

.card {
  border-radius: 4px;
  border-radius: 1px solid #efefef;
  max-width: 25.5rem;
}

.flash {
  box-shadow: 0px 4px 4px rgba(255, 57, 8, 0.25);
}

.backtest {
  box-shadow: 0px 4px 4px rgba(56, 63, 236, 0.25);
}

.analyse {
  box-shadow: 0px 4px 4px rgba(112, 209, 31, 0.25);
}

.balance {
  box-shadow: 0px 4px 4px rgba(56, 63, 236, 0.25);
}

.strategy {
  box-shadow: 0px 4px 4px rgba(226, 168, 56, 0.44);
}

.board {
  background: linear-gradient(86.64deg, #383fec 88.72%, #686efd 97.84%);
  border-radius: 4px;
}

.board-btn {
  background: #ffffff;
  color: #686efd;
  width: max-content;
}

.board-btn:hover {
  background: #efefef;
  color: #383fec;
}


@media only screen and (max-width: 420px) {
  .auth-popup {
    height: 388px !important;
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
  }

  .auth-popup-api {
    height: 439px !important;
  }

  .banner-desc {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .hero_image {
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .why-op-box {
    border-radius: 4px;
    background: #F2F3FF;
    height: 121px;
    grid-column: span 6;
  }

  .features-h1 {
    font-size: 20px !important;
    margin-top: 54px;
  }

  .list-box {
    gap: 8px;
  }

  .why-op-box {
    height: 89px;
  }

  .box-title {
    font-size: 14px;
  }

  .box-description {
    font-size: 14px;
  }

  .get-all-this li {
    font-size: 14px;
  }

  .pricing-title {
    margin-top: 0px;
    font-size: 20px;
    margin-top: 19px;
  }

  .prc-mobile {
    margin-top: 41px !important;
  }

  .price-desc {
    font-size: 14px;
    margin-bottom: 0;
  }

  .strikethrough-text {
    font-size: 16px;
    margin-top: 19px;
  }

  .bold-price {
    font-size: 24px;
  }

  .faq-question {
    width: auto;
  }

  .faq-question {
    font-size: 14px;
    padding: 8px 24.5px 14px 24.5px;
  }

  .inner-footer {
    padding: 0px 34px;
  }

  .footer-text {
    font-size: 11px;
  }

  .footer-mail {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .footer-link-end {
    text-align: end;
  }

  .get-started-btn {
    width: 125px;
  }

}